import { $ } from "../vendors";

const menu = (() => {
  const dropdownMenuToggle = () => {
    const menuDropdown = $('.menu .menu__dropdown');
    $('.menu .has-submenu').on('click', function(e) {
      e.preventDefault();
      var target = $(this).data('bs-target');
      menuDropdown.not(target).collapse('hide');
      $(target).collapse('toggle');
    });
  };
  const hamburgerMenuToggle = () => {
    const hamburgerMenuBtn = $('.mobile-header__hamburger-menu-btn');
    const sidebarMobileMenu = $('.sidebar .sidebar__left');
    const mobileHeader = $('.mobile-header');
    hamburgerMenuBtn.on('click',function(e){
      e.preventDefault();
      $(this).toggleClass('js-active');
      sidebarMobileMenu.toggleClass('js-active');
      mobileHeader.toggleClass('js-active');
      $('body').toggleClass('js-body-lock');
    });
  };
  const init = () => ({
    dropdownMenuToggle: dropdownMenuToggle(),
    hamburgerMenuToggle: hamburgerMenuToggle()
  });

  return {
    init
  };
})();

export default menu;
