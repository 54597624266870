import { $ } from "../vendors";

const datepicker = (() => {
  const inputTypeDate = () => {
    $('.bootstrap-datepicker').datepicker({
      format: 'dd.mm.yyyy',
      language: 'tr',
      autoclose: true,
      todayHighlight: true,
    });
  };
  const init = () => ({
    inputTypeDate: inputTypeDate(),
  });

  return {
    init
  };
})();

export default datepicker;
