import { $ } from "./vendors";
import { Slider } from "./components";
import { Popup } from "./components";
import { Mask } from "./components";
import { Select } from "./components";
import { Datepicker } from "./components";

import { Menu } from "./partials";

window.$ = $;
window.jQuery = $;

$(document).ready(() => {
  Slider.init();
  Popup.init();
  Mask.init();
  Select.init();
  Datepicker.init();

  Menu.init();
});

