import { $ } from "../vendors";

const mask = (() => {
  const phoneMask = () => {
    if($('.phone-mask').length > 0){
      const $phoneInput = $('.phone-mask');
      Inputmask({
        mask: '599 999 99 99',
      }).mask($phoneInput);
    }
  };
  const init = () => ({
    phoneMask: phoneMask(),
  });

  return {
    init
  };
})();

export default mask;
