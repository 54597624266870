import { $ } from "../vendors";

const popup = (() => {
  const lightGallery = () => {
    $("#lightgallery").lightGallery({
      thumbnail: true,
      selector: '.js-open-gallery',
      share: false,
      download: false,
      subHtmlSelectorRelative: true,
      iframeHeight : '200px',
      share: false,
      autoplay: false,
      actualSize : false,
    });
  };
 
  const init = () => ({
    lightGallery: lightGallery(),
  });

  return {
    init
  };
})();

export default popup;
