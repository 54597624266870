import $ from "jquery";
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap-datepicker/dist/js/bootstrap-datepicker.min';
import 'bootstrap-datepicker/dist/locales/bootstrap-datepicker.tr.min';
import 'lightgallery';
import 'lg-zoom';
import 'lg-thumbnail';
import 'lg-pager';
import 'lg-fullscreen';
import 'lg-autoplay';
import "inputmask";
import 'select2';

import {
  Swiper,
  Virtual,
  Keyboard,
  Mousewheel,
  Navigation,
  Pagination,
  Scrollbar,
  Parallax,
  Zoom,
  Lazy,
  Controller,
  A11y,
  History,
  HashNavigation,
  Autoplay,
  EffectFade,
  EffectCube,
  EffectFlip,
  EffectCoverflow,
  Thumbs
} from "swiper";
Swiper.use([
  Virtual,
  Keyboard,
  Mousewheel,
  Navigation,
  Pagination,
  Scrollbar,
  Parallax,
  Zoom,
  Lazy,
  Controller,
  A11y,
  History,
  HashNavigation,
  Autoplay,
  EffectFade,
  EffectCube,
  EffectFlip,
  EffectCoverflow,
  Thumbs 
]);
window.$ = window.jQuery = $;

export { $, Swiper };