import { $, Swiper } from "../vendors";

const slider = (() => {
  const generalInfoSlider = () => {
    var generalInfo = new Swiper('.general-info-slider .swiper-container', {
      slidesPerView: 1.5,
      slidesPerGroup: 1,
      loop: false,
      speed: 1000,
      watchOverflow: true,
      spaceBetween: 15,
      navigation: {
        nextEl: '.general-info-slider .swiper-btn-next',
        prevEl: '.general-info-slider .swiper-btn-prev',
      },
      breakpoints: {
        768: {
          slidesPerView: 2.5,
          slidesPerGroup: 1,
        },
        1000: {
          slidesPerView: 3.5,
          slidesPerGroup: 1,
        },
        1536: {
          slidesPerView: 4.5,
          slidesPerGroup: 1,
        },
      },
    });

    if($('.general-info-slider .swiper-btn-next').hasClass('swiper-button-disabled') && $('.general-info-slider .swiper-btn-prev').hasClass('swiper-button-disabled')){
      $('.general-info-slider .swiper-control').hide();
    }
    function sliderControlItemsHeight(){
      if($('.general-info-slider').length > 0 && $(window).innerWidth() > 992) {
        const slideHeight = $('.general-info-slider .swiper-slide').outerHeight();
        const sliderControl = $('.general-info-slider .swiper-control');
        const sliderControlItems = $('.general-info-slider .swiper-control > div');
        sliderControl.css({
          height : slideHeight,
        });
        sliderControlItems.css({
          height : (slideHeight/2) - 5,
        });
      }
    }
    sliderControlItemsHeight();
    window.addEventListener('resize', function(event){
      sliderControlItemsHeight();
    });
  };
  const announcementsSlider = () => {
    var announcements = new Swiper('.announcement-slider .swiper-container', {
      slidesPerView: 1.1,
      slidesPerGroup: 1,
      loop: false,
      speed: 1000,
      watchOverflow: true,
      spaceBetween: 10,
      breakpoints: {
        768: {
          slidesPerView: 2.2,
          slidesPerGroup: 1,
          spaceBetween: 15,
        },
        1000: {
          slidesPerView: 2.7,
          slidesPerGroup: 1,
          spaceBetween: 20,
        },
        1100: {
          slidesPerView: 2.9,
          slidesPerGroup: 1,
          spaceBetween: 30,
        },
        1536: {
          slidesPerView: 3.3,
          slidesPerGroup: 1,
          spaceBetween: 30,
        },
        1700: {
          slidesPerView: 4.3,
          slidesPerGroup: 1,
          spaceBetween: 30,
        }
      },
    });
  };
  const campaignsSlider = () => {
    var campaignsImageSliderSwiper = new Swiper('.campaigns-slider .campaigns-slider__image-slider .swiper-container', {
      slidesPerView: 1,
      slidesPerGroup: 1,
      loop: false,
      speed: 1000,
      navigation: {
        nextEl: '.campaigns-slider .campaigns-slider__image-slider .swiper-btn-next',
        prevEl: '.campaigns-slider .campaigns-slider__image-slider .swiper-btn-prev',
      },
      pagination: {
        el: '.campaigns-slider .campaigns-slider__image-slider .swiper-pagination',
        clickable: true,
      },
    });
  };
  const productNestedSlider = () => {
    var productsSlider = new Swiper('.product-nested-slider .swiper-container', {
      slidesPerView: 1,
      spaceBetween: 10,
      loop: false,
      slidesPerGroup: 1,
      observer: true,
      observeParents: true,
      nested: true,
      effect: "fade",
      watchOverflow: true,
      pagination: {
        el: '.product-nested-slider .swiper-pagination',
        clickable: true,
      },
    });
  };
  const productHoverGallery = () => {
    /*Product hover gallery*/
    $('.product-nested-slider').each(function(index,item){
      var itemLength = $(this).find('.swiper-slide').length;
      $(this).find(".swiper-container").append("<div class='hover-image'></div>")
      for(var i=0; i< $(this).find('.swiper-slide').length; i++) {
        $(this).find('.hover-image').append('<div data-id="'+(i)+'" style="width:calc(100% / '+itemLength+'); height: 100%; float:left"></div>');
      }
    })

    $(".hover-image >  div").mouseover(function () {
      $(this).closest(".product-nested-slider").find(".swiper-pagination span").eq($(this).data("id")).trigger("click")
    });
    $(".hover-image >  div").mouseout(function () {
      $(this).closest(".product-nested-slider").find(".swiper-pagination span").eq(0).trigger("click")
    });
  };
  const productDetailGallerySlider = () => {
    var galleryThumb = new Swiper('.product-detail__articles .gallery-thumb .swiper-container', {
      slidesPerView: 3,
      slidesPerGroup: 1,
      loop: false,
      speed: 1000,
      spaceBetween: 15,
      watchOverflow: true,
      observer: true,
    	observeParents: true,
      breakpoints: {
        576: {
          slidesPerView: 4,
        },
        1025: {
          slidesPerView: 4,
        }
      },
      on: {
        init: function () {
          const numberOfSlides = this.slides.length;
          if($(window).innerWidth() < 1025) {
            if(numberOfSlides < 4) {
              $('.product-detail__articlesl .gallery-thumb .swiper-control-custom').hide()
            }
          }
          if($(window).innerWidth() >= 1025) {
            if(numberOfSlides < 5) {
              $('.product-detail__articles .gallery-thumb .swiper-control-custom').hide()
            }
          }
        }
      }
    });
    var galleryTop = new Swiper('.product-detail__articles .gallery-top .swiper-container', {
      slidesPerView: 1,
      slidesPerGroup: 1,
      loop: false,
      speed: 1000,
      spaceBetween: 10,
      watchOverflow: true,
      thumbs: {
        swiper: galleryThumb
      },
    });
    
  };
  const init = () => ({
    generalInfoSlider: generalInfoSlider(),
    announcementsSlider: announcementsSlider(),
    campaignsSlider: campaignsSlider(),
    productNestedSlider: productNestedSlider(),
    productHoverGallery: productHoverGallery(),
    productDetailGallerySlider: productDetailGallerySlider()
  });

  return {
    init
  };
})();

export default slider;
